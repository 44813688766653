<template>
  <div id="title">
    <div class="title_">
      <img class="logo" src="../../assets/img/login/logo.png" alt="logo" />
      <div class="logo_text">
        <div>海外华人</div>
        <b>征婚网</b>
      </div>
    </div>
    <b class="text">海外华人征婚网运营后台系统</b>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
#title {
  position: fixed;
  top: 0;
  width: 100vw;
  height: 100px;
  background-color: var(--white);
  color: var(--pink);
  display: flex;
  .title_ {
    min-width: 200px;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    .logo {
      width: 72px;
      height: 74px;
    }
    .logo_text {
      height: 74px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-end;
      margin-left: 20px;
      div {
        font-size: 16px;
      }
      b {
        font-size: 30px;
      }
    }
  }
  .text {
    min-width: 500px;
    flex: 1;
    font-size: 36px;
    line-height: 100px;
  }
}
</style>