<template>
  <div id="login">
    <log-title></log-title>
    <img class="frist" src="../../assets/img/login/frist.png" alt="" />
    <div class="login">
      <b>账户登录</b>
      <el-form :model="loginFrom" :rules="rules" ref="login">
        <el-form-item prop="account">
          <el-input placeholder="请输入账号名" v-model="loginFrom.account">
            <i slot="prefix">
              <img
                style="width: 21px; height: 21px"
                src="../../assets/img/login/admin_icon.png"
                alt
              />
            </i>
          </el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input
            class="pass"
            placeholder="请输入密码"
            v-model="loginFrom.password"
            show-password
            :maxlength="16"
          >
            <i slot="prefix">
              <img
                style="width: 21px; height: 21px"
                src="../../assets/img/login/my_icon.png"
                alt
              />
            </i>
          </el-input>
        </el-form-item>
        <el-form-item prop="code">
          <div class="code">
            <el-input
              placeholder="请输入验证码"
              v-model="loginFrom.code"
              :maxlength="4"
            >
              <i slot="prefix">
                <img
                  style="width: 21px; height: 21px"
                  src="../../assets/img/login/lock_icon.png"
                  alt
                />
              </i>
            </el-input>
            <div class="authCode" slot="append" @click="initCode">
              {{ authCode }}
            </div>
          </div>
        </el-form-item>
      </el-form>
      <el-button class="btn" @click="submitForm">登录</el-button>
    </div>
  </div>
</template>

<script>
import logTitle from "./logTitle.vue";

import { mapMutations } from "vuex";
import { request } from "../../API/request";
import { getCode } from "../../components/code/code";

export default {
  name: "login",
  components: { logTitle },
  data() {
    // 校检验证码是否一致
    let checkCode = (rule, value, callback) => {
      if (value != this.authCode) {
        callback(new Error());
      } else {
        callback();
      }
    };
    return {
      authCode: "", // 生成的验证码
      loginFrom: {
        account: "", // 用户名
        password: "", // 密码
        code: "", // 验证码
      },
      rules: {
        account: [{ required: true, message: "账号不能为空", trigger: "blur" }],
        password: [
          { required: true, message: "密码不能为空", trigger: "blur" },
        ],
        code: [
          { required: true, message: "验证码不能为空" },
          {
            validator: checkCode,
            message: "验证码输入不一致！",
            trigger: "blur",
          },
        ],
      },
    };
  },
  created() {
    // 生成验证码
    this.initCode();
  },
  methods: {
    ...mapMutations(["changeLogin"]),
    // 重置验证码
    initCode() {
      this.authCode = getCode();
    },
    // 登录
    submitForm() {
      this.$refs.login.validate(async (valid) => {
        if (valid) {
          request({
            url: "/login",
            method: "POST",
            data: {
              account: this.loginFrom.account,
              password: this.loginFrom.password,
            },
          }).then((res) => {
            if (res.code === 200) {
              this.$message.success(res.message);
              res.data.userInfo.Authorization = res.data.Authorization;
              localStorage.setItem(
                "userInfo",
                JSON.stringify(res.data.userInfo)
              );
              this.$router.push("/Home");
            } else {
              this.initCode();
              this.$message.error(res.message);
            }
          });
        } else {
          this.initCode();
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
#login {
  position: relative;
  height: 100vh;
  width: 100%;
  background-image: linear-gradient(to right, #bfa3ff, #ffb5b5);
  overflow-y: auto;
  .frist {
    margin: 200px 0 0 387px;
    width: 600px;
    height: 506px;
  }
  .login {
    position: absolute;
    top: 260px;
    left: 53%;
    padding: 20px;
    background: var(--white);
    width: 300px;
    b {
      font-size: 26px;
      color: var(--black);
    }
    .el-form {
      margin-top: 20px;
      .code {
        position: relative;
        .authCode {
          position: absolute;
          top: 10%;
          right: 0;
          height: 80%;
          line-height: 1;
          width: 30%;
          cursor: pointer;
          z-index: 99999;
          text-align: center;
          font-weight: bold;
          font-size: 30px;
          color: var(--pink);
        }
      }
    }
    .el-button {
      margin: 40px 0;
      width: 100%;
      background-image: linear-gradient(to right, #bfa3ff, #ffb5b5);
      color: var(--white);
      font-size: var(--minSize);
    }
  }
}
</style>
